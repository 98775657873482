<template>
  <b-card class="d-flex flex-column align-items-center">
    <div class="mb-1" style="width: 842px"></div>

    <div class="mb-1 d-flex" style="width: 842px">
      <button
        @click="generatePDF('customerDetails', 'Customer Details')"
        :class="'btn btn-primary'"
      >
        Download PDF
      </button>
      <div class="log-buttons" v-if="logsExist">
        <div class="log-button">
          <button
            @click="getPreviousLog"
            :class="'btn btn-primary ' + (disablePrevButton && 'd-none')"
          >
            Back
          </button>
        </div>
        <div class="log-button">
          <button
            @click="getNextLog"
            :class="'btn btn-primary ' + (disableNextButton && 'd-none')"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- new  -->
    <b-card style="width: 842px" id="customerDetails" class="border">
      <div class="mb-3">
        <h2>{{ form.company }}</h2>
        <p>Company Name</p>
      </div>
      <div class="container-fluid"><hr /></div>
      <h2 class="details-heading mt-3 mb-2">Address Details</h2>

      <div class="row">
        <div class="col-md-3 mb-2">
          <div class="pr-md-5">
            <p class="details-subheading">Address</p>
            <p>{{ form.address }}</p>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="pr-md-5">
            <p class="details-subheading">City</p>
            <p>{{ form.city }}</p>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="pr-md-5">
            <p class="details-subheading">Zip Code</p>
            <p>{{ form.zip_code }}</p>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="pr-md-5">
            <p class="details-subheading">State</p>
            <p>{{ form.state }}</p>
          </div>
        </div>
      </div>

      <div class="container-fluid"><hr /></div>
      <!-- contact -->
      <div class="d-flex flex-wrap justify-content-between">
        <div class="mb-3 flex-grow-1">
          <h2 class="details-heading mb-2 mt-3">Contact Information</h2>
          <div class="d-flex flex-column">
            <div class="d-flex mb-1">
              <p class="details-subheading">Contact Type:</p>
              <p>{{ getNamesById(form.contact_type, "contact_type") }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Phone Number:</p>
              <p>{{ form.phone }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Fax:</p>
              <p>{{ form.fax }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Mobile Phone:</p>
              <p>{{ form.mobile_phone }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Website:</p>
              <p>{{ form.website }}</p>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <h2 class="details-heading mb-2 mt-3 mr-md-5">
            Additional Information
          </h2>
          <div class="d-flex flex-column">
            <div class="d-flex mb-1">
              <p class="details-subheading">Interest:</p>
              <p>{{ getNamesById(form.interest, "interest") }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Language:</p>
              <p>{{ form.language }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Vat:</p>
              <p>{{ form.vat }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Note:</p>
              <p>{{ form.note }}</p>
            </div>
            <div class="d-flex mb-1">
              <p class="details-subheading">Christmas Card:</p>
              <p>{{ form.christmas_card }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid"><hr /></div>
      <!-- contact end -->

      <!-- Bank Details -->
      <h2 class="details-heading mb-2 mt-3">Bank Details</h2>
      <div class="d-flex mb-3 flex-column">
        <div class="d-flex mb-1">
          <p class="details-subheading">Bank Name:</p>
          <p>{{ getNamesById(form.interest, "interest") }}</p>
        </div>
        <div class="d-flex mb-1">
          <p class="details-subheading">Account Number / IBAN:</p>
          <p>{{ form.language }}</p>
        </div>
        <div class="d-flex mb-1">
          <p class="details-subheading">Swift Code:</p>
          <p>{{ form.vat }}</p>
        </div>
      </div>
    </b-card>
    <!-- Bank Details End -->
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BTable,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import Treeselect from "@riophae/vue-treeselect";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  addVendor,
  updateVendor,
  getVendorByID,
  getVendorLogs,
} from "@/apiServices/VendorServices";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url, length } from "@validations";
import { labels } from "@/utils/constants";
import _ from "lodash";
import { getNamesById, generatePDF } from "@/utils/helpers";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,
    Treeselect,
    flatPickr,
    FeatherIcon,
    BFormSelect,
    BFormSelectOption,
    ToastificationContentVue,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      disablePrevButton: false,
      disableNextButton: true,
      logsExist: false,
      current_log: 0,
      log_count: 0,
      contact_id: null,
      is_copy: false,
      vendor_list: [],
      form: {
        contactPersonArray: [],
        company: null,
        name: null,
        first_name: null,
        position: null,
        number: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        state: null,
        country: null,
        website: null,
        phone: null,
        phone_data: null,
        mobile_phone: "",
        mobile_phone_data: null,
        fax: "",
        fax_data: null,
        language: null,
        contact_type: null,
        interest: [],
        christmas_card: null,
        christmas_gift: null,
        note: null,
        bank_name: null,
        swift_code: null,
        account_number: null,
        vat: null,
        required,
        length,
        email,
        url,
      },
      // country_list: [],
      // language_list: [],
      // contact_type_list: [],
      // interest_list: [],
      defaultCountry: "CH",
    };
  },
  computed: {
    inputDisabled() {
      return this.contact_id
        ? !this.$can("update", "contact")
        : !this.$can("create", "contact");
    },
    inputReadDisabled() {
      return this.contact_id
        ? this.$can("read", "contact")
        : this.$can("read", "contact");
    },
  },
  methods: {
    getNamesById,
    getPreviousLog() {
      if (this.current_log == this.log_count) {
        this.current_log = this.log_count - 1;
      } else {
        this.current_log++;
      }
      this.getVendor();
    },
    getNextLog() {
      if (this.current_log == 0) {
        this.current_log = 0;
      } else {
        this.current_log--;
      }
      this.getVendor();
    },
    handleCountryChanged(object, str) {
      const val = _.get(this, str);
      if (!val || val.split(" ").length <= 1) {
        _.set(this, str, `+${object.dialCode}`);
      }
    },
    getCountryFlag(country_id) {
      if (country_id) {
        this.$store.state.master.country &&
          this.$store.state.master.country.length > 0 &&
          this.$store.state.master.country.map((country) => {
            if (country.lbl_id == country_id) {
              this.defaultCountry = country.lbl_value1;
            }
          });
      } else {
        this.defaultCountry = "CH";
      }
    },
    phoneEntered(number, numObj) {
      this.form.phone_data = numObj;
    },

    mobilePhoneEntered(number, numObj) {
      this.form.mobile_phone_data = numObj;
    },

    faxEntered(number, numObj) {
      this.form.fax_data = numObj;
    },

    async getVendorResponse() {
      this.vendor = await getVendorLogs(this.contact_id);
      if (this.vendor.data.data[0]) {
        this.logsExist = true;
        this.log_count = this.vendor.data.data.length - 1;
        this.getVendor();
      } else {
        this.vendor = await getVendorByID(this.contact_id);
        this.getVendor();
      }
    },

    getVendor() {
      let response;

      this.current_log === this.log_count
        ? (this.disablePrevButton = true)
        : (this.disablePrevButton = false);
      this.current_log === 0
        ? (this.disableNextButton = true)
        : (this.disableNextButton = false);

      if (Array.isArray(this.vendor.data.data)) {
        response = JSON.parse(
          this.vendor.data.data[this.current_log].json_text
        );
      } else {
        response = this.vendor.data.data;
      }

      if (response.vendorData) {
        const data = response.vendorData;
        this.form = {
          company: data.company,
          name: data.name,
          first_name: data.fname,
          position: data.position,
          number: data.number,
          email: data.email,
          address: data.street,
          zip_code: data.zip_code,
          city: data.city,
          state: data.state,
          country: data.country ? parseInt(data.country) : null,
          website: data.website,
          phone: data.phone ? data.phone : "+41",
          account_number: data.account_number,
          mobile_phone: data.mobile ? data.mobile : "+41",
          christmas_card: data.c_card == 1 ? true : false,
          christmas_gift: data.c_gift == 1 ? true : false,
          fax: data.fax ? data.fax : "+41",
          language: data.language ? parseInt(data.language) : null,
          contact_type: data.contact_type,
          interest: [],
          note: data.note,
          bank_name: data.bank_name,
          swift_code: data.ifsc,
          vat: data.vat,
          contactPersonArray: response.vendorContactPerson,
        };

        data.intrest &&
          data.intrest.split(",").map((item) => {
            this.$store.state.master.interest.map((inter) => {
              if (inter.lbl_id == item) {
                this.form.interest.push(inter);
              }
            });
          });

        if (data.country) this.getCountryFlag(data.country);
      }
    },

    async submitForm() {
      this.$refs.simpleRules.validate().then(async (sucess) => {
        if (sucess) {
          try {
            let interests = "";
            if (this.form.interest && this.form.interest.length > 0) {
              this.form.interest.map((item, index) => {
                interests +=
                  item.lbl_id +
                  (index != this.form.interest.length - 1 ? "," : "");
              });
            }

            if (this.is_copy) {
              this.contact_id = null;
            }

            let data = {
              vendor_id: this.contact_id,
              fname: this.form.first_name,
              name: this.form.name,
              company: this.form.company,
              position: this.form.position,
              street: this.form.address,
              number: this.form.number,
              zip_code: this.form.zip_code,
              city: this.form.city,
              country: this.form.country,
              email: this.form.email,
              website: this.form.website,
              phone: this.form.phone,
              mobile: this.form.mobile_phone,
              language: this.form.language,
              contact_type: this.form.contact_type,
              intrest: interests,
              c_card: this.form.christmas_card,
              c_gift: this.form.christmas_gift,
              note: this.form.note,
              bank_name: this.form.bank_name,
              account_number: this.form.account_number,
              vat: this.form.vat,
              ifsc: this.form.swift_code,
              fax: this.form.fax,
              state: this.form.state,
              contactPersonArray: this.form.contactPersonArray,
            };

            let response = null;
            if (this.contact_id) {
              response = await updateVendor(this.contact_id, data);
            } else {
              response = await addVendor(data);
            }

            if (response.data.status) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message || "Success",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.go(-1);
            } else {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message || "Validation Failed",
                  icon: "EditIcon",
                  variant: "warning",
                },
              });
            }
          } catch (err) {
            console.error("Error => ", err.message);
          }
        }
      });
    },

    resetForm() {
      this.form = {
        contactPersonArray: [],
        company: null,
        name: null,
        first_name: null,
        position: null,
        number: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        state: null,
        country: null,
        website: null,
        phone: null,
        phone_data: null,
        mobile_phone: null,
        mobile_phone_data: null,
        fax: null,
        fax_data: null,
        language: null,
        contact_type: [],
        interest: [],
        christmas_card: null,
        christmas_gift: null,
        note: null,
        bank_name: null,
        swift_code: null,
        account_number: null,
        vat: null,
        email: null,
        url: null,
        defaultCountry: "CH",
      };
    },

    addContactPerson() {
      let obj = {
        name: "",
        position: "",
        contactNo: "",
        emailId: "",
        status: true,
        contactperson_id: null,
      };
      this.form.contactPersonArray.push(obj);
    },
    generatePDF,
  },

  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const is_copy = urlParams.get("copy");

    if (is_copy && is_copy == "1") {
      this.is_copy = true;
    }

    const contact_id = this.$route.params.contact_id;
    if (contact_id && !isNaN(contact_id)) {
      this.contact_id = contact_id;
      this.getVendorResponse();
    } else {
      this.addContactPerson();
    }

    this.$store.dispatch("master/setLabelMaster", { key: labels.country });
    this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    this.$store.dispatch("master/setLabelMaster", { key: labels.interest });
    this.$store.dispatch("master/setLabelMaster", { key: labels.contact_type });
  },
};
</script>

<style scoped lang="scss">
.form-rows {
  margin-bottom: 30px;
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  border-color: gray;
}

.add-row {
  display: block;
  margin: auto;
  cursor: pointer;
}

.remove-row {
  display: block;
  margin: auto;
  cursor: pointer;
}

.collapse-group {
  margin-top: 30px;
  margin-bottom: 30px;
}

.country-selector__input {
  border-color: #1f304a !important;
}

.details-subheading {
  font-weight: bold;
}
.details-heading {
  font-weight: bolder;
}

.log-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
